<template>
    <v-container fluid>
        <v-form @submit.prevent="saveProfile">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col v-if="$route.name !== 'profile'" cols="1" style="max-width: 80px">
                                        <v-btn v-if="$route.params.id" :to="{name: 'partner'}"
                                               class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                        <v-btn v-else :to="{name: 'profile'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ $t('profile') }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="3" md="2" class="text-center">
                                <make-avatar v-model="photo" :aspect-ratio="(1/1)"/>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="9" md="10">
                                <v-row>
                                    <v-col class="py-0" cols="12" sm="12">
                                        <ValidationProvider ref="partner" rules="required|min:3|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="partner" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-account"
                                                          :label="$t('partner_name')" color="primary"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="email" rules="required|email|min:5|max:50"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="email" type="email"
                                                          :error-messages="errors" :error="!valid"
                                                          :disabled="loading || !can(['partner', 'manager'])"
                                                          :readonly="!can(['partner', 'manager'])"
                                                          prepend-icon="mdi-email" label="Email"
                                                          color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="phone" rules="phone"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="phoneRaw" type="tel" v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-phone"
                                                          :label="$t('phone')" color="primary"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="role" rules="required"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="role" :error="!valid" :error-messages="errors"
                                                      :disabled="loading || !can(['manager1'])"
                                                      :readonly="!can(['manager1'])"
                                                      :items="roleItems" item-text="name" item-value="id"
                                                      prepend-icon="mdi-account-hard-hat" color="primary"
                                                      :label="$t('role')"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <v-subheader class="headline">{{ $t('partner_access_password') }}</v-subheader>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="password" name="password"
                                                    :rules="form_required + 'min:8'" vid="password"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="password" :error="!valid && create" :disabled="loading"
                                                  :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                                  :type="passwordHidden ? 'password' : 'text'" :error-messages="errors"
                                                  @click:append="() => (passwordHidden = !passwordHidden)"
                                                  prepend-icon="mdi-lock" :label="$t('enter_password')"
                                                  color="primary" clearable required></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="confirmation_password" name="confirmation_password"
                                                    rules="required_if:password|confirmed:password"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="password_confirmation" :error="!valid" :disabled="loading"
                                                  :append-icon="passwordConfirmHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                                  :type="passwordHidden ? 'password' : 'text'" :error-messages="errors"
                                                  @click:append="() => (passwordConfirmHidden = !passwordConfirmHidden)"
                                                  prepend-icon="mdi-lock" :label="$t('repeat_password')"
                                                  color="primary" clearable required></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-8">
                        <v-progress-linear v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading"
                               :loading="loading" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'
import MakeAvatar from '../components/MakeAvatar.vue'
import {mapActions, mapGetters} from "vuex";
import debounce from "lodash/debounce";

export default {
    name: 'AdministratorForm',
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        MakeAvatar,
    },
    inject: ['forceRerender'],
    data() {
        return {
            progress: 0,
            create: false,
            phoneRaw: null,
            id: null,
            partner: null,
            email: null,
            notes: null,
            active: false,
            deleted: false,
            password: null,
            passwordHidden: true,
            password_confirmation: null,
            passwordConfirmHidden: true,
            loading: false,
            loadingCities: false,
            loadingDeliveryAreas: false,
            errors: {},
            photo: null,
            role: null,
            roleItems: [],


        }
    },
    computed: {
        ...mapGetters(['lang', 'phoneMask', 'defaultAvatar']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
        form_required: function () {
            if (this.$route.name === 'partner.edit' || (this.$route.name === 'profile' && this.$auth.check())) {
                return ''
            }
            return 'required|'
        },
    },
    watch: {
        password(val) {
            if (val && val !== this.password_confirmation) {
                this.$refs.confirmation_password.setErrors([
                    this.$t('validations.rules.confirmed', {'_field_': this.$t('validations.fields.confirmation_password')})
                ])
            }
        },
        password_confirmation(val) {
            if (val && val !== this.password) {
                this.$refs.password.setErrors([
                    this.$t('validations.rules.confirmed', {'_field_': this.$t('validations.fields.password')})
                ])
            } else {
                this.$refs.password.reset()
            }
        },
    },
    mounted() {
        this.getRoles()
        this.checkCreate()
    },
    methods: {
        ...mapActions(['setUserName', 'setUserPosition', 'setUserAvatar']),
        checkCreate() {
            this.getProfile(this.$auth.user().id);

        },


        async getRoles() {
            let params = {};
            params.filter = 'getroles'
            await this.$http
                .get(`partner/role`, {
                    params: params,
                })
                .then(res => {
                    this.roleItems = res.body.data;
                })
                .catch(err => {
                    this.roleItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_roles'))
                })
        },
        async getProfile(id) {
            var _this = this;
            this.progress = 0
            this.loading = true
            this.id = id
            await this.$http
                .get(`partner/profile/${id}`, {
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.partner = res.body.data.name
                    this.phoneRaw = res.body.data.phone
                    this.email = res.body.data.email
                    this.photo = res.body.data.photo
                    this.role = res.body.data.role

                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_partner'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveProfile() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.photo) {
                if (this.photo.length > 250) {
                    var mimeType = this.getMimeType(this.photo)
                    var blob = this.dataURL64toBlob(this.photo)
                    if (mimeType && blob) {
                        formData.append('photo', blob, mimeType)
                    }
                } else {
                    formData.append('photo', this.photo)
                }
            }
            if (this.partner) {
                formData.append('partner', this.partner)
            }
            if (this.phone) {
                formData.append('phone', this.phone)
            }


            if (this.password) {
                formData.append('password', this.password)
            }




            // Save
            await this.$http
                .put(`partner/profile/${this.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('partner_has_been_updated'))
                    if ((this.$route.params.id && this.$route.params.id == this.$auth.user().id) || (this.$route.name === 'profile' && this.$auth.check())) {
                        this.$auth.fetch()
                        .then(res => {
                            this.setUserName(this.$auth.user().name)
                            this.setUserPosition(this.$auth.user().position)
                            this.setUserAvatar((this.$auth.user().photo ? this.$auth.user().photo : this.defaultAvatar))

                        })
                    }
                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('partner_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        }
    }
}
</script>
